<template>
  <div class="wrapper">
    <el-card shadow="never" style="margin: 20px 0; border: none">
      <h2>订单详情</h2>
      <el-breadcrumb separator="/" style="margin-bottom: 20px">
        <el-breadcrumb-item>用户中心</el-breadcrumb-item>
        <el-breadcrumb-item>交易管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      </el-breadcrumb>
      <template v-if="['0', '21'].includes(detail.sts)">
        <div class="steps_wrapper">
          <el-steps :active="active" align-center process-status="finish">
            <el-step title="企业贴现申请"></el-step>
            <el-step title="银行确认贴现申请"></el-step>
            <el-step title="登录网银去贴现背书"></el-step>
            <el-step title="银行签收并放款"></el-step>
            <el-step title="交易完成"></el-step>
          </el-steps>
        </div>
        <div class="tip_message">
          <img
            src="./image/success.png"
            width="54"
            alt="成功"
            style="margin: 64px 0 32px"
          />
          <h3>贴现申请成功</h3>
          <p>
            因承兑人同业授信额度可能随时发生变化，请您尽快前往电票所在行的企业网银客户端，在线实现票据贴。如有疑问请联系客服经理。
          </p>
        </div>
      </template>
      <div
        class="tip_message"
        v-if="['30', '91', '92', '35', '36'].includes(detail.sts)"
      >
        <img
          src="./image/error.png"
          width="54"
          alt="失败"
          style="margin: 64px 0 32px"
        />
        <h3>贴现失败！</h3>
        <p>请核对并修改以下信息后，再重新提交。</p>
        <div class="error_content">
          <p>失败原因</p>
          <ul class="error_list">
            <li>{{ detail.stsDesc }}</li>
          </ul>
        </div>
      </div>
      <div class="detail">
        <div class="title">订单信息</div>
        <el-row class="content__wrapper">
          <el-col :span="12" class="col">
            <span class="label">订单编号</span>
            <p class="value">{{ detail.platformOrderNo }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">订单提交时间</span>
            <p class="value">{{ detail.applyDate }}</p>
          </el-col>
        </el-row>

        <div class="title">贴现银行信息</div>
        <el-row class="content__wrapper">
          <el-col :span="12" class="col">
            <span class="label">贴入行名称</span>
            <p class="value">{{ detail.bankAccName }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">贴入行银行行号</span>
            <p class="value">{{ detail.bankAcCode }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">贴入行大额行号</span>
            <p class="value">{{ detail.bankCnasp }}</p>
          </el-col>
        </el-row>

        <div class="title">结算信息</div>
        <el-row class="content__wrapper">
          <el-col :span="12" class="col">
            <span class="label">清算方式</span>
            <p class="value">线下结算</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">收款账户</span>
            <p class="value">{{ detail.payeeAccount }}</p>
          </el-col>
        </el-row>

        <div class="title">票据信息</div>
        <el-row class="content__wrapper">
          <el-col :span="12" class="col">
            <span class="label">票据号码</span>
            <p class="value">{{ detail.draftNo }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">票面金额</span>
            <p class="value">{{ _formatAmount(detail.draftAmount) }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">贴现成交金额</span>
            <p class="value">{{ _formatAmount(detail.discountAmount) }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">承兑人名称</span>
            <p class="value">{{ detail.accepterName }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">贴现利率</span>
            <p class="value">{{ detail.discountRateFormat }}</p>
          </el-col>
          <el-col :span="12" class="col">
            <span class="label">票据到期日期</span>
            <p class="value">{{ detail.maturityDate }}</p>
          </el-col>
        </el-row>
      </div>
      <div class="btn_wrapper">
        <el-button
          type="primary"
          size="small"
          style="width: 200px"
          @click="handleCancel"
        >
          返回
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getDetail } from './api'
import numeral from 'numeral'

export default {
  props: {
    orderNo: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      // 21：银行受理成功  active 为 2
      // 0：贴现成功  active 为 4
      active: 0,
      detail: {},
    }
  },
  mounted() {
    this._getDetail()
  },
  methods: {
    // 格式化金额
    _formatAmount(amount) {
      if (amount) {
        return numeral(amount).format('0,0.00')
      }
      return ''
    },
    async _getDetail() {
      try {
        this.detail = await getDetail(this.orderNo)

        this.detail.discountRateFormat =
          this.detail.discountRate &&
          numeral(this.detail.discountRate).format('0.00%')

        const { sts } = this.detail
        if (sts === '21') {
          this.active = 2
        } else if (sts === '0') {
          this.active = 4
        }
      } catch (err) {
        console.error(err)
      }
    },
    handleCancel() {
      if (!this.$route.query.isDiscount) {
        this.$router.go(-1)
      } else {
        this.$router.push('/bill-financing')
      }
    },
  },
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 16px;
  color: #333;
  line-height: 24px;
  margin-bottom: 14px;
}

.steps_wrapper {
  width: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 60px;
}

.tip_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  h3 {
    text-align: center;
    font-size: 22px;
    color: #333;
    line-height: 32px;
    margin-bottom: 16px;
  }

  p {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 22px;
  }

  .error_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 816px;
    box-sizing: border-box;
    padding: 30px 32px;
    margin-top: 48px;
    background-color: #f7f8fa;

    p {
      font-size: 16px;
      color: #333;
      line-height: 26px;
      margin-bottom: 10px;
    }

    .error_list {
      li {
        font-size: 14px;
        line-height: 40px;
        background: url('./image/error.png') no-repeat 0 13px;
        background-size: 15px;
        color: #333;
        padding-left: 24px;
      }
    }
  }
}

.detail {
  .title {
    background: #f6f8fc;
    line-height: 40px;
    font-size: 14px;
    color: #333;
    padding-left: 16px;
    font-weight: 600;
  }

  .content__wrapper {
    .col {
      display: flex;
      padding-left: 16px;

      .label {
        width: 150px;
        font-size: 14px;
        color: #999;
        line-height: 40px;
      }
      .value {
        flex: 1;
        font-size: 14px;
        color: #333;
        line-height: 40px;
      }
    }
  }
}

.btn_wrapper {
  margin-top: 40px;
  text-align: center;
}

::v-deep.el-step__line {
  margin: 0 18px !important;
}
::v-deep.is-finish {
  .el-step__icon {
    background-color: #409eff;

    .el-step__icon-inner {
      color: #fff;
    }
  }
}

::v-deep.el-step__title {
  font-size: 15px;
}
</style>
